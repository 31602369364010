



































































import {
  Component, PropSync, Vue, Watch,
} from 'vue-property-decorator';

import StepChooseGoalsPocketViewModel from '@/vue-app/view-models/components/flagship/flagship-link-goals/steps-pocket/step-choose-goals-pocket-view-model';

@Component({ name: 'StepChooseGoalsPocket' })
export default class StepChooseGoalsPocket extends Vue {
  @PropSync('associatedProductId', { type: String, required: true })
  associated_product_id!: string;

  @PropSync('isLoading', { type: Boolean, required: true, default: false })
  is_loading!: boolean;

  @Watch('step_choose_goals_pocket_model.associated_product_selected')
  associatedProductSelected() {
    this.$emit('changeGoalsSelected', this.step_choose_goals_pocket_model.associated_product_selected);
  }

  step_choose_goals_pocket_model = Vue.observable(
    new StepChooseGoalsPocketViewModel(this),
  );

  created() {
    this.step_choose_goals_pocket_model.initialize(this.associated_product_id);
  }
}
